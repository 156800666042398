import { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { Input, Table, Spin, Avatar, Checkbox, Space } from "antd";
import { DatePickerPopover } from "../../components/DatePickerPopover";
import { setOpenInfo } from "../../store/user";
import { InfoModal } from "../../components/InfoModal";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import {
  convertToLocalDate,
  convertDateToISO861,
  formatDateString,
  reversFormatDateString,
} from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { clearErrorStudio } from "../../store/studios";
import { getStudioData, getErrorStudio } from "../../store/studios/selectors";
import { getOpenInfoSelector } from "../../store/user/selectors";
import {
  getCalculation,
  postCalculation,
  clearError,
  clearErrorCreate,
  clearSuccessCreate,
} from "../../store/calculation";
import {
  getCalculationData,
  getOpenCalculationSelector,
  getCalculationLoading,
  getError,
  getSuccess,
  getSuccessCreate,
} from "../../store/calculation/selectors";
import displayErrors from "../../utils/displayErrors";
import { CalculationDetailModal } from "../../components/CalculationDetailModal";

export const CalculationPage = () => {
  const dispatch = useDispatch();
  const studioData = useSelector(getStudioData);
  const error = useSelector(getError);
  const isLoading = useSelector(getCalculationLoading);
  const errorStudio = useSelector(getErrorStudio);
  const calculationData = useSelector(getCalculationData);
  const isOpenCalculation = useSelector(getOpenCalculationSelector);
  const [width, setWidth] = useState(window.innerWidth);
  const [fileSearch, setFileSearch] = useState("");
  const [filtersArray, setFiltersArray] = useState([]);
  const [dateAfterFilter, setDateAfterFilter] = useState(null);
  const [dateBeforeFilter, setDateBeforeFilter] = useState(null);
  const [openDate, setOpenDate] = useState(false);
  const [openCalculationDetail, setOpenCalculationDetail] = useState(false);
  const [viewDataFilter, setViewDataFilter] = useState(null);
  const openInfo = useSelector(getOpenInfoSelector);
  const success = useSelector(getSuccess);
  const successCreate = useSelector(getSuccessCreate);
  const [checkedRows, setCheckedRows] = useState([]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [sum, setSum] = useState(0);
  const [ordering, setOrdering] = useState({
    y_name: null,
  });

  const getOrderBy = () => {
    let orderBy = "";

    Object.keys(ordering).forEach((key) => {
      if (ordering[key] === "ascend") orderBy = key;
      if (ordering[key] === "descend") orderBy = "-" + key;
    });

    return orderBy;
  };

  const handleSort = (field) => {
    let tempOrdering = {
      y_name: null,
    };

    switch (ordering[field]) {
      case "ascend":
        tempOrdering[field] = "descend";
        break;
      case "descend":
        tempOrdering[field] = null;
        break;
      default:
        tempOrdering[field] = "ascend";
    }

    setOrdering(tempOrdering);
  };

  const columns = [
    createColumn(
      () => {
        const hasTrueRow = calculationData?.data?.some(
          (row) => row.payments.length === 0 && row.total_salary > 0
        );

        return (
          isOpenCalculation &&
          hasTrueRow && (
            <Checkbox
              checked={isCheckedAll}
              onChange={(e) => {
                e.stopPropagation();
                setIsCheckedAll(e.target.checked);
                if (e.target.checked) {
                  const visibleRows = calculationData?.data?.filter(
                    (row) => row.payments.length === 0 && row.total_salary > 0
                  );
                  setCheckedRows(visibleRows.map((row) => row));
                } else {
                  setCheckedRows([]);
                }
              }}
            />
          )
        );
      },
      "checkbox",
      (_, row) => {
        const isChecked = checkedRows.includes(row);
        return (
          isOpenCalculation &&
          row.payments.length === 0 &&
          row.total_salary > 0 && (
            <Checkbox
              checked={isChecked}
              onChange={(evt) => {
                evt.stopPropagation();
                let newCheckedRows;
                if (!isChecked) {
                  newCheckedRows = [...checkedRows, row];
                } else {
                  newCheckedRows = checkedRows.filter(
                    (item) => item.id !== row.id
                  );
                }
                setCheckedRows(newCheckedRows);

                const visibleRows = calculationData?.data?.filter(
                  (row) => row.payments.length === 0 && row.total_salary > 0
                );
                const isAllVisibleChecked =
                  newCheckedRows.length === visibleRows.length;

                setIsCheckedAll(isAllVisibleChecked);
              }}
            />
          )
        );
      },
      30,
      "center"
    ),
    createColumn(
      "Mастер",
      "master",
      (_, row) => {
        return (
          <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
            <Avatar
              style={{ width: 25, minWidth: 25, height: 25 }}
              src={row?.master?.y_avatar}
            />
            <Space direction="vertical" style={{ gap: 0 }}>
              <span style={{ textWrap: "nowrap" }}>{row?.master?.y_name}</span>
              <span
                style={{
                  color: "#8B8B8B",
                  fontSize: 12,
                  lineHeight: "14.52px",
                  textWrap: "nowrap",
                }}
              >
                {row?.master?.y_specialization}
              </span>
            </Space>
          </div>
        );
      },
      50,
      undefined,
      ordering?.master,
      () => {
        return 0;
      }
    ),
    createColumn(
      "Зарплата по Yclients",
      "y_client_salary_data",
      (v) => {
        const salary = parseFloat(v?.salary);
        return `${salary.toLocaleString("ru-RU")} р.`;
      },
      100
    ),
    createColumn(
      "Шрафт",
      "total_penalty",
      (v) => {
        return `${v.toLocaleString("ru-RU")} р.`;
      },
      100
    ),
    createColumn(
      "Бонус",
      "total_bonus",
      (v) => {
        return `${v.toLocaleString("ru-RU")} р.`;
      },
      100
    ),
    createColumn(
      "Премия",
      "total_bounty",
      (v) => {
        return `${v.toLocaleString("ru-RU")} р.`;
      },
      100
    ),
    createColumn(
      "Выплата до зарплаты",
      "total_prepayment",
      (v) => {
        return `${v.toLocaleString("ru-RU")} р.`;
      },
      100
    ),
    createColumn(
      "Официальная зарплата",
      "total_official_salary",
      (v) => {
        return `${v.toLocaleString("ru-RU")} р.`;
      },
      100
    ),
    createColumn(
      "Выплаты",
      "payments",
      (v) => {
        return (
          <Space direction="vertical" style={{ gap: 0 }}>
            {v?.map((item) => {
              return (
                <Space key={item.id} direction="horizontal" style={{ gap: 5 }}>
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      fontSize: 12,
                      fontWeight: 500,
                    }}
                  >
                    {formatDateString(item?.period_start_date)} -{" "}
                    {formatDateString(item?.period_end_date)}
                  </span>
                  <span
                    style={{
                      fontSize: 12,
                      fontWeight: 700,
                      textWrap: "nowrap",
                    }}
                  >
                    {item?.paid?.toLocaleString("ru-RU")} р.
                  </span>
                </Space>
              );
            })}
          </Space>
        );
      },
      150
    ),
    createColumn(
      "К выплате",
      "total_salary",
      (v) => {
        return `${v.toLocaleString("ru-RU")} р.`;
      },
      100
    ),
  ];

  function createColumn(title, key, render, width, align, sortOrder, sorter) {
    return {
      title,
      key,
      dataIndex: key,
      render,
      width,
      align,
      sortOrder,
      sorter,
    };
  }

  const changeArrayFilters = ({ key, value, text, title }) => {
    const isIncludes = (el, filterName) => {
      return el.key.includes(filterName) !== key.includes(filterName);
    };

    let filtersResult = filtersArray.filter((i) => {
      return isIncludes(i, "master");
    });

    setFiltersArray(
      value || value === 0
        ? [
            ...filtersResult,
            { key: key, value: value, text: text, title: title },
          ]
        : filtersResult
    );
  };

  const handleChangeDateAfter = (e) => {
    if (e?.$d) {
      setDateAfterFilter(e?.$d.toLocaleDateString("ru-RU"));
      if (dateBeforeFilter) {
        setViewDataFilter(
          e?.$d.toLocaleDateString("ru-RU") + " - " + dateBeforeFilter
        );
      } else {
        setViewDataFilter(e?.$d.toLocaleDateString("ru-RU") + " - ");
      }
    } else {
      if (dateAfterFilter) {
        setViewDataFilter(dateBeforeFilter);
      }
      setDateAfterFilter(null);
    }
  };

  const handleChangeDateBefore = (e) => {
    if (e?.$d) {
      setDateBeforeFilter(e?.$d.toLocaleDateString("ru-RU"));
      if (dateAfterFilter) {
        setViewDataFilter(
          dateAfterFilter + " - " + e?.$d.toLocaleDateString("ru-RU")
        );
      } else {
        setViewDataFilter(" - " + e?.$d.toLocaleDateString("ru-RU"));
      }
    } else {
      if (dateBeforeFilter) {
        setViewDataFilter(dateAfterFilter);
      }
      setDateBeforeFilter(null);
    }
  };

  const fetchData = () => {
    let filters = false;
    let offsetValue = null;
    let limitValue = null;

    if (dateAfterFilter || dateBeforeFilter) {
      filters = {
        start_date: convertDateToISO861(dateAfterFilter),
        end_date: convertDateToISO861(dateBeforeFilter),
      };
    }

    // if (pagination && nextPage) {
    //   const urlParams = new URLSearchParams(nextPage.split("?")[1]);
    //   offsetValue = urlParams.get("offset");
    // }

    dispatch(clearErrorStudio());
    dispatch(clearError());
    dispatch(clearErrorCreate());

    if (dateAfterFilter && dateBeforeFilter) {
      dispatch(
        getCalculation({
          studio_y_id:
            JSON.parse(sessionStorage.getItem("studio"))?.y_id ||
            studioData?.y_id,
          filters: filters,
          filtersArray: filtersArray,
          ordering: getOrderBy(),
        })
      );
    }
  };

  // const clearFilters = () => {
  //   setFileSearch("");
  //   setViewDataFilter(null);
  //   setDateBeforeFilter(null);
  //   setDateAfterFilter(null);
  //   setFiltersArray([]);
  // };

  useEffect(() => {
    if (!isOpenCalculation && checkedRows?.length > 0) {
      const payments = checkedRows.map((item) => {
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split("T")[0];
        return {
          paid: item?.total_salary,
          period_start_date: reversFormatDateString(dateAfterFilter),
          period_end_date: reversFormatDateString(dateBeforeFilter),
          pay_date_time: formattedDate,
          related_corrections: item?.related_corrections,
          master: item?.master?.id,
          studio: item?.master?.studio?.id,
        };
      });

      dispatch(
        postCalculation({
          studio_y_id: studioData?.y_id,
          payments: payments,
        })
      );

      setCheckedRows([]);
      dispatch(clearSuccessCreate());
    }
  }, [isOpenCalculation]);

  useEffect(() => {
    if (studioData) {
      const timerRefresh = setTimeout(fetchData, 0);
      return () => {
        clearTimeout(timerRefresh);
      };
    }
  }, [
    dateAfterFilter,
    dateBeforeFilter,
    filtersArray,
    studioData,
    success,
    successCreate,
    ordering,
  ]);

  useEffect(() => {
    if (isOpenCalculation) {
      if (checkedRows?.length > 0) {
        const sumCheckedRows = checkedRows.reduce(
          (acc, row) => acc + row.total_salary,
          0
        );
        setSum(sumCheckedRows.toLocaleString("ru-RU"));
      } else {
        setSum(0);
      }
    } else if (calculationData?.data) {
      const sumData = calculationData.data.reduce(
        (acc, item) => acc + item.total_salary,
        0
      );
      setSum(sumData.toLocaleString("ru-RU"));
    } else {
      setSum(0);
    }
  }, [calculationData, isOpenCalculation, checkedRows]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, [width]);

  useEffect(() => {
    const currentDate = new Date();
    const previousMonth = currentDate.getMonth() - 1;
    const firstDayOfPreviousMonth = new Date(
      currentDate.getFullYear(),
      previousMonth,
      1
    );
    const lastDayOfPreviousMonth = new Date(
      currentDate.getFullYear(),
      previousMonth + 1,
      0
    );

    setDateAfterFilter(
      convertToLocalDate(firstDayOfPreviousMonth).split(" ")[0]
    );
    setDateBeforeFilter(
      convertToLocalDate(lastDayOfPreviousMonth).split(" ")[0]
    );
    setViewDataFilter(
      convertToLocalDate(firstDayOfPreviousMonth).split(" ")[0] +
        " - " +
        convertToLocalDate(lastDayOfPreviousMonth).split(" ")[0]
    );
  }, []);

  return (
    <>
      <>
        <div
          style={{
            display: "flex",
            gap: width > 640 ? 12 : 9,
            position: "relative",
            paddingBottom: width > 640 ? 25 : 16,
          }}
        >
          <DatePickerPopover
            openDate={openDate}
            setOpenDate={setOpenDate}
            viewDataFilter={viewDataFilter}
            dateAfterFilter={dateAfterFilter}
            dateBeforeFilter={dateBeforeFilter}
            // handleSetDateFilters={handleSetDateFilters}
            handleChangeDateBefore={handleChangeDateBefore}
            handleChangeDateAfter={handleChangeDateAfter}
          />

          {width > 640 && (
            <Space direction="vertical" style={{ width: "100%" }}>
              <Input
                style={{ borderRadius: "2px" }}
                prefix={<SearchOutlined style={{ color: "#C1C2C9" }} />}
                value={fileSearch}
                placeholder="Поиск по имени мастера"
                suffix={
                  <CloseOutlined
                    style={{
                      color: fileSearch ? "red" : "black",
                    }}
                    onClick={() => {
                      setFileSearch("");
                      setFiltersArray(
                        filtersArray.filter((v) => v.key !== "q")
                      );
                    }}
                  />
                }
                onChange={(evt) => {
                  setFileSearch(evt.target.value);
                  changeArrayFilters({
                    item: "",
                    key: "q",
                    value: evt.target.value,
                    text: evt.target.value,
                    title: "q",
                  });
                }}
              />
            </Space>
          )}

          {/* {(dateAfterFilter ||
            dateBeforeFilter ||
            filtersArray?.length > 0) && (
            <Button
              onClick={clearFilters}
              style={{ borderRadius: 2, backgroundColor: "#4D7CD6" }}
              type={"primary"}
            >
              Сбросить
            </Button>
          )} */}
        </div>
      </>
      <div
        style={{
          marginBottom: 10,
          fontSize: 12,
          fontWeight: 300,
          marginLeft: 5,
        }}
      >
        Показано {calculationData?.data?.length || 0} из{" "}
        {calculationData?.data?.length || 0}
      </div>
      <div
        style={{
          maxHeight: isOpenCalculation
            ? "calc(100vh - 270px)"
            : "calc(100vh - 225px)",
          width: "100%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {(error || errorStudio) && displayErrors(error || errorStudio)}
        <Table
          columns={columns}
          rowKey={(obj) => obj.id}
          size="small"
          pagination={false}
          loading={isLoading && <Spin size="large" />}
          className={`${styles.table} ${styles.table_header}`}
          dataSource={calculationData?.data || []}
          onRow={(record) => {
            if (isOpenCalculation) {
              return;
            } else {
              return {
                onClick: (evt) => {
                  evt.stopPropagation();
                  if (evt.target.type !== "checkbox") {
                    setOpenCalculationDetail({
                      open: true,
                      record: record,
                    });
                  }
                },
              };
            }
          }}
          locale={{
            triggerDesc: "сортировать от Я до А",
            triggerAsc: "сортировать от А до Я",
            cancelSort: "сбросить",
          }}
          onHeaderRow={() => {
            return {
              onClick: (e) => {
                switch (e.target.innerText) {
                  case "MАСТЕР":
                    handleSort("y_name");
                    break;
                }
              },
            };
          }}
        />
      </div>
      <div
        style={{
          fontSize: 16,
          fontWeight: 400,
          display: "flex",
          justifyContent: "end",
          gap: 5,
          marginTop: 5,
        }}
      >
        {isOpenCalculation ? "Выбрано" : "Итого"}
        <span
          style={{
            fontSize: 16,
            fontWeight: 700,
          }}
        >
          {sum}
        </span>
        р.
      </div>
      {openCalculationDetail && (
        <CalculationDetailModal
          openDetails={openCalculationDetail}
          setOpenDetails={setOpenCalculationDetail}
          viewDataFilter={viewDataFilter}
        />
      )}
      {openInfo && (
        <InfoModal
          openModal={openInfo}
          setOpenModal={setOpenInfo}
          title={"Информация по расчетам"}
          text={"Тут будет информация"}
        />
      )}
    </>
  );
};
