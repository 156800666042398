import { useState, useEffect } from "react";
import { Button, Col, Drawer, Row, Typography, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  postPayments,
  clearSuccessCreate,
  clearErrorCreate,
  setOpenPayments,
} from "../../store/payments";
import {
  getSuccessCreate,
  getErrorCreate,
  getPaymentsLoading,
  getPaymentsDropDownSelector,
} from "../../store/payments/selectors";
import displayErrors from "../../utils/displayErrors";
import { formatDateString, convertToLocalDate } from "../../utils/utils";
import { SuccessModalSide } from "../SuccessModalSide";
import iconMoneyGreen from "../../images/iconMoneyGreen.svg";
import iconMoney from "../../images/iconMoney.svg";
import iconClose from "../../images/iconClose.svg";
import styles from "./styles.module.css";

export const CreatePaymentsModal = ({
  paymentsArr,
  setPaymentsArr,
  studioData,
  openPaymentsCreate,
  setOpenPaymentsCreate,
  setCheckedRows,
}) => {
  const dispatch = useDispatch();
  const successCreate = useSelector(getSuccessCreate);
  const errorCreate = useSelector(getErrorCreate);
  const loader = useSelector(getPaymentsLoading);
  const boxOfficeList = useSelector(getPaymentsDropDownSelector);
  const [width, setWidth] = useState(window.innerWidth);

  const matchingTitles = boxOfficeList
    .filter((item) => item.id === paymentsArr[0]?.account_id)
    .map((item) => item.title);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, [width]);

  return (
    <Drawer
      styles={{
        body: { padding: width > 640 ? "0 64px 20px" : "0 24px 20px" },
      }}
      open={openPaymentsCreate}
      onCancel={() => {
        dispatch(clearErrorCreate());
        dispatch(clearSuccessCreate());
        setOpenPaymentsCreate(false);
        setPaymentsArr([]);
        setCheckedRows([]);
      }}
      onClose={() => {
        dispatch(clearErrorCreate());
        dispatch(clearSuccessCreate());
        setOpenPaymentsCreate(false);
        setPaymentsArr([]);
        setCheckedRows([]);
      }}
      footer={null}
      title={
        <Typography.Title style={{ margin: 0 }} level={3}>
          Внесение расходов по ЗП
        </Typography.Title>
      }
      width={650}
      maskClosable={true}
    >
      {successCreate ? (
        <SuccessModalSide
          close={() => {
            dispatch(clearErrorCreate());
            dispatch(clearSuccessCreate());
            setOpenPaymentsCreate(false);
            setPaymentsArr([]);
            setCheckedRows([]);
            dispatch(setOpenPayments());
          }}
          title="Выплаты успешно добавлен!"
        />
      ) : (
        <>
          {loader ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 300,
              }}
            >
              <Spin size="large" />
            </div>
          ) : (
            <div style={{ marginTop: "20px" }}>
              {errorCreate && displayErrors(errorCreate)}
              <p style={{ fontSize: 14, lineHeight: "16.94px" }}>
                Нажав кнопку{" "}
                <Typography.Text style={{ fontWeight: 700 }}>
                  “Провести”
                </Typography.Text>{" "}
                ниже, вы внесете в студию{" "}
                <Typography.Text style={{ fontWeight: 700 }}>
                  “{studioData?.y_title}”
                </Typography.Text>{" "}
                следующие расходы в кассу{" "}
                <Typography.Text style={{ fontWeight: 700 }}>
                  “{matchingTitles}”
                </Typography.Text>{" "}
                платежи от{" "}
                <Typography.Text style={{ fontWeight: 700 }}>
                  “{convertToLocalDate(paymentsArr[0].date)}”
                </Typography.Text>{" "}
                с использованием статьи платежа -{" "}
                <Typography.Text style={{ fontWeight: 700 }}>
                  Зарплата персонала
                </Typography.Text>
                :
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 14,
                  height: "100%",
                  minHeight: 400,
                }}
              >
                {paymentsArr?.map((item) => {
                  return (
                    <Row
                      gutter={12}
                      justify={"start"}
                      style={{ alignItems: "center" }}
                    >
                      <Col>
                        <img src={iconMoneyGreen} />
                      </Col>
                      <Col>
                        <p style={{ margin: 0 }}>
                          <Typography.Text style={{ fontWeight: 700 }}>
                            {item?.data?.master?.y_name} - {item?.data?.paid} p.
                          </Typography.Text>{" "}
                          ЗП за период{" "}
                          <Typography.Text style={{ fontWeight: 700 }}>
                            с {formatDateString(item?.data?.period_start_date)}{" "}
                            по {formatDateString(item?.data?.period_end_date)}
                          </Typography.Text>
                        </p>
                      </Col>
                    </Row>
                  );
                })}
              </div>
              <Row gutter={24} justify={"center"}>
                <Col
                  style={{
                    maxWidth: 173,
                    width: "100%",
                    display: "flex",
                    alignItems: "end",
                  }}
                >
                  <Button
                    className={styles.button_close}
                    style={{ width: "100%", borderRadius: 2 }}
                    onClick={() => {
                      dispatch(clearSuccessCreate());
                      dispatch(clearErrorCreate());
                      setOpenPaymentsCreate(false);
                      setPaymentsArr([]);
                      setCheckedRows([]);
                    }}
                  >
                    <img src={iconClose} alt="close" />
                    <span className={styles.button_text}>Отменить</span>
                  </Button>
                </Col>
                <Col
                  style={{
                    maxWidth: 173,
                    width: "100%",
                    display: "flex",
                    alignItems: "end",
                  }}
                >
                  <Button
                    className={styles.button}
                    onClick={() => {
                      const payments = paymentsArr.map((item) => {
                        return {
                          id: item.data.id,
                          date: item.date,
                          account_id: item.account_id,
                        };
                      });
                      dispatch(
                        postPayments({
                          studio_y_id: studioData?.y_id,
                          payments: payments,
                        })
                      );
                      dispatch(clearSuccessCreate());
                      dispatch(clearErrorCreate());
                      setPaymentsArr([]);
                      setCheckedRows([]);
                    }}
                    type="primary"
                  >
                    <img src={iconMoney} alt="money" />
                    <span className={styles.button_text}>Провести</span>
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </>
      )}
    </Drawer>
  );
};
