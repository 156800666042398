import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getUserData } from "../../store/user/selectors";
import IconQuality from "../../images/iconQuality.svg";
import IconArrowUp from "../../images/iconArrowUp.svg";
import IconArrowDown from "../../images/iconArrowDown.svg";
import IconSalary from "../../images/iconSalary.svg";
import IconSettings from "../../images/iconSettings.svg";
import IconPersonnel from "../../images/iconPersonnel.svg";
import IconAnalytics from "../../images/iconAnalytics.svg";
import styles from "./styles.module.css";

export const SidebarStudios = ({
  pageId,
  openMenu,
  openSidebar,
  openQuality,
  setOpenQuality,
  openSalaries,
  setOpenPersonnel,
  openPersonnel,
  setOpenSalaries,
  openAnalytics,
  setOpenAnalytics,
  openSettings,
  setOpenSettings,
  handleMouseLeave,
  handleMouseEnter,
}) => {
  const userData = useSelector(getUserData);

  return openSidebar ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        padding: "17px 0 0 12px",
      }}
    >
      <div key="personnel" className={`${styles.customMenuItem}`}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px 15px 10px 13px",
            backgroundColor: openPersonnel && "#5DCBC499",
            borderRadius: "2px 2px 0 0",
          }}
          onClick={() => {
            setOpenPersonnel(!openPersonnel);
            setOpenSalaries(false);
            setOpenQuality(false);
            setOpenAnalytics(false);
            setOpenSettings(false);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 12,
            }}
          >
            <div
              style={{
                width: "22px",
                height: "22px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={IconPersonnel}
                alt="Персонал"
                style={{ width: "22px", height: "22px" }}
              />
            </div>
            <span>Персонал</span>
          </div>
          {!openPersonnel ? (
            <img src={IconArrowDown} alt="arrowDown" />
          ) : (
            <img src={IconArrowUp} alt="arrowUp" />
          )}
        </div>
        {openPersonnel && (
          <div
            style={{
              padding: "9px 0px 23px 27px",
              display: "flex",
              flexDirection: "column",
              gap: 12,
              backgroundColor: openPersonnel && "#FFFFFF",
              borderRadius: 2,
            }}
          >
            <Link
              className={`${styles.customLink} ${
                (pageId === "staff" || pageId === "staff_create") &&
                styles.customLinkSelected
              }`}
              to={`/staff/${
                JSON.parse(sessionStorage.getItem("studio"))?.y_id
              }`}
            >
              Сотрудники
            </Link>
          </div>
        )}
      </div>
      <div key="quality" className={`${styles.customMenuItem}`}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px 15px 10px 13px",
            backgroundColor: openQuality && "#5DCBC499",
            borderRadius: "2px 2px 0 0",
          }}
          onClick={() => {
            setOpenQuality(!openQuality);
            setOpenSalaries(false);
            setOpenPersonnel(false);
            setOpenAnalytics(false);
            setOpenSettings(false);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 12,
            }}
          >
            <div
              style={{
                width: "22px",
                height: "22px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={IconQuality}
                alt="Качество"
                style={{ width: "22px", height: "22px" }}
              />
            </div>
            <span>Качество</span>
          </div>
          {!openQuality ? (
            <img src={IconArrowDown} alt="arrowDown" />
          ) : (
            <img src={IconArrowUp} alt="arrowUp" />
          )}
        </div>
        {openQuality && (
          <div
            style={{
              padding: "9px 0px 23px 27px",
              display: "flex",
              flexDirection: "column",
              gap: 12,
              backgroundColor: openQuality && "#FFFFFF",
              borderRadius: 2,
            }}
          >
            <Link
              className={`${styles.customLink} ${
                pageId === "complaints" && styles.customLinkSelected
              }`}
              to={`/complaints/${
                JSON.parse(sessionStorage.getItem("studio"))?.y_id
              }`}
            >
              Жалобы
            </Link>
          </div>
        )}
      </div>
      <div key="salaries" className={`${styles.customMenuItem}`}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px 15px 10px 13px",
            backgroundColor: openSalaries && "#5DCBC499",
            borderRadius: "2px 2px 0 0",
          }}
          onClick={() => {
            setOpenSalaries(!openSalaries);
            setOpenQuality(false);
            setOpenPersonnel(false);
            setOpenAnalytics(false);
            setOpenSettings(false);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 12,
            }}
          >
            <div
              style={{
                width: "22px",
                height: "22px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={IconSalary} alt="Зарплаты" />
            </div>
            <span>Зарплаты</span>
          </div>
          {!openSalaries ? (
            <img src={IconArrowDown} alt="arrowDown" />
          ) : (
            <img src={IconArrowUp} alt="arrowUp" />
          )}
        </div>
        {openSalaries && (
          <div
            style={{
              padding: "9px 0px 23px 27px",
              display: "flex",
              flexDirection: "column",
              gap: 12,
              backgroundColor: openSalaries && "#FFFFFF",
              borderRadius: 2,
            }}
          >
            <Link
              className={`${styles.customLink} ${
                pageId === "corrections" && styles.customLinkSelected
              }`}
              to={`/corrections/${
                JSON.parse(sessionStorage.getItem("studio"))?.y_id
              }`}
            >
              Коррекции
            </Link>
            {(JSON.parse(sessionStorage.getItem("studio"))?.user_role ===
              "owner" ||
              userData?.user_studios[0].user_role === "owner") && (
              <Link
                className={`${styles.customLink} ${
                  pageId === "payments" && styles.customLinkSelected
                }`}
                to={`/payments/${
                  JSON.parse(sessionStorage.getItem("studio"))?.y_id
                }`}
              >
                Выплаты
              </Link>
            )}
            {(JSON.parse(sessionStorage.getItem("studio"))?.user_role ===
              "owner" ||
              userData?.user_studios[0].user_role === "owner") && (
              <Link
                className={`${styles.customLink} ${
                  pageId === "calculation" && styles.customLinkSelected
                }`}
                to={`/calculation/${
                  JSON.parse(sessionStorage.getItem("studio"))?.y_id
                }`}
              >
                Расчет
              </Link>
            )}
          </div>
        )}
      </div>
      {(JSON.parse(sessionStorage.getItem("studio"))?.user_role === "owner" ||
        JSON.parse(sessionStorage.getItem("studio"))?.user_role === "manager" ||
        userData?.user_studios[0].user_role === "owner" ||
        userData?.user_studios[0].user_role === "manager") && (
        <div key="аnalytics" className={`${styles.customMenuItem}`}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px 15px 10px 13px",
              backgroundColor: openAnalytics && "#5DCBC499",
              borderRadius: "2px 2px 0 0",
            }}
            onClick={() => {
              setOpenAnalytics(!openAnalytics);
              setOpenPersonnel(false);
              setOpenQuality(false);
              setOpenSalaries(false);
              setOpenSettings(false);
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 12,
              }}
            >
              <div
                style={{
                  width: "22px",
                  height: "22px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={IconAnalytics} alt="Аналитика" />
              </div>
              <span>Аналитика</span>
            </div>
            {!openAnalytics ? (
              <img src={IconArrowDown} alt="arrowDown" />
            ) : (
              <img src={IconArrowUp} alt="arrowUp" />
            )}
          </div>
          {openAnalytics && (
            <div
              style={{
                padding: "9px 0px 23px 27px",
                display: "flex",
                flexDirection: "column",
                gap: 12,
                backgroundColor: openAnalytics && "#FFFFFF",
                borderRadius: 2,
              }}
            >
              {(JSON.parse(sessionStorage.getItem("studio"))?.user_role ===
                "owner" ||
                JSON.parse(sessionStorage.getItem("studio"))?.user_role ===
                  "manager" ||
                userData?.user_studios[0].user_role === "owner" ||
                userData?.user_studios[0].user_role === "manager") && (
                <Link
                  className={`${styles.customLink} ${
                    pageId === "planning" && styles.customLinkSelected
                  }`}
                  to={`/planning/${
                    JSON.parse(sessionStorage.getItem("studio"))?.y_id
                  }`}
                >
                  Планирование
                </Link>
              )}
            </div>
          )}
        </div>
      )}
      {(JSON.parse(sessionStorage.getItem("studio"))?.user_role === "owner" ||
        JSON.parse(sessionStorage.getItem("studio"))?.user_role === "manager" ||
        userData?.user_studios[0].user_role === "owner" ||
        userData?.user_studios[0].user_role === "manager") && (
        <Link
          key="settings"
          className={`${styles.customMenuItem}`}
          to={"/settings"}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px 15px 10px 13px",
              backgroundColor: openSettings && "#5DCBC499",
              borderRadius: "2px 2px 0 0",
            }}
            onClick={() => {
              setOpenSettings(true);
              setOpenSalaries(false);
              setOpenQuality(false);
              setOpenAnalytics(false);
              setOpenPersonnel(false);
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 12,
              }}
            >
              <div
                style={{
                  width: "22px",
                  height: "22px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={IconSettings} alt="Настройки" />
              </div>
              <span style={{ color: "black" }}>Настройки</span>
            </div>
          </div>
        </Link>
      )}
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        padding: "10px 0 0",
      }}
    >
      <div
        key="personnel"
        onMouseEnter={() => handleMouseEnter("personnel")}
        onMouseLeave={handleMouseLeave}
        className={`${styles.customMenuItem}`}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 0",
            backgroundColor: pageId === "staff" && "#5DCBC499",
            borderRadius: "2px 2px 0 0",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 12,
            }}
          >
            <img src={IconPersonnel} alt="Персонал" />
          </div>
        </div>
        {openMenu === "personnel" && (
          <div className={styles.subMenuItemContainer}>
            <div
              className={`${
                openMenu === "personnel" && styles.subMenuItemAcive
              } ${styles.subMenuItem}`}
              style={{
                backgroundColor: openMenu === "personnel" && "#FFFFFF",
              }}
            >
              <Link
                className={`${styles.customLink} ${
                  pageId === "staff" && styles.customLinkSelected
                }`}
                to={`/staff/${
                  JSON.parse(sessionStorage.getItem("studio"))?.y_id
                }`}
              >
                Сотрудники
              </Link>
            </div>
          </div>
        )}
      </div>
      <div
        key="quality"
        onMouseEnter={() => handleMouseEnter("quality")}
        onMouseLeave={handleMouseLeave}
        className={`${styles.customMenuItem}`}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 0",
            backgroundColor: pageId === "complaints" && "#5DCBC499",
            borderRadius: "2px 2px 0 0",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 12,
            }}
          >
            <img src={IconQuality} alt="Качество" />
          </div>
        </div>
        {openMenu === "quality" && (
          <div className={styles.subMenuItemContainer}>
            <div
              className={`${
                openMenu === "quality" && styles.subMenuItemAcive
              } ${styles.subMenuItem}`}
              style={{
                backgroundColor: openMenu === "quality" && "#FFFFFF",
              }}
            >
              <Link
                className={`${styles.customLink} ${
                  pageId === "complaints" && styles.customLinkSelected
                }`}
                to={`/complaints/${
                  JSON.parse(sessionStorage.getItem("studio"))?.y_id
                }`}
              >
                Жалобы
              </Link>
            </div>
          </div>
        )}
      </div>
      <div
        key="salaries"
        onMouseEnter={() => handleMouseEnter("salaries")}
        onMouseLeave={handleMouseLeave}
        className={`${styles.customMenuItem}`}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 0",
            backgroundColor:
              (pageId === "corrections" ||
                pageId === "calculation" ||
                pageId === "payments") &&
              "#5DCBC499",
            borderRadius: "2px 2px 0 0",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 12,
            }}
          >
            <img src={IconSalary} alt="Зарплаты" />
          </div>
        </div>
        {openMenu === "salaries" && (
          <div className={styles.subMenuItemContainer}>
            <div
              className={`${
                openMenu === "salaries" && styles.subMenuItemAcive
              } ${styles.subMenuItem}`}
              style={{
                backgroundColor: openMenu === "salaries" && "#FFFFFF",
              }}
            >
              <Link
                className={`${styles.customLink} ${
                  pageId === "corrections" && styles.customLinkSelected
                }`}
                to={`/corrections/${
                  JSON.parse(sessionStorage.getItem("studio"))?.y_id
                }`}
              >
                Коррекции
              </Link>
              {(JSON.parse(sessionStorage.getItem("studio"))?.user_role ===
                "owner" ||
                userData?.user_studios[0].user_role === "owner") && (
                <Link
                  className={`${styles.customLink} ${
                    pageId === "payments" && styles.customLinkSelected
                  }`}
                  to={`/payments/${
                    JSON.parse(sessionStorage.getItem("studio"))?.y_id
                  }`}
                >
                  Выплаты
                </Link>
              )}
              {(JSON.parse(sessionStorage.getItem("studio"))?.user_role ===
                "owner" ||
                userData?.user_studios[0].user_role === "owner") && (
                <Link
                  className={`${styles.customLink} ${
                    pageId === "calculation" && styles.customLinkSelected
                  }`}
                  to={`/calculation/${
                    JSON.parse(sessionStorage.getItem("studio"))?.y_id
                  }`}
                >
                  Расчет
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
      {(JSON.parse(sessionStorage.getItem("studio"))?.user_role === "owner" ||
        JSON.parse(sessionStorage.getItem("studio"))?.user_role === "manager" ||
        userData?.user_studios[0].user_role === "owner" ||
        userData?.user_studios[0].user_role === "manager") && (
        <div
          key="аnalytics"
          onMouseEnter={() => handleMouseEnter("аnalytics")}
          onMouseLeave={handleMouseLeave}
          className={`${styles.customMenuItem}`}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px 0",
              backgroundColor: pageId === "planning" && "#5DCBC499",
              borderRadius: "2px 2px 0 0",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 12,
              }}
            >
              <img src={IconAnalytics} alt="Аналитика" />
            </div>
          </div>
          {openMenu === "аnalytics" && (
            <div className={styles.subMenuItemContainer}>
              <div
                className={`${
                  openMenu === "аnalytics" && styles.subMenuItemAcive
                } ${styles.subMenuItem}`}
                style={{
                  backgroundColor: openMenu === "аnalytics" && "#FFFFFF",
                }}
              >
                {(JSON.parse(sessionStorage.getItem("studio"))?.user_role ===
                  "owner" ||
                  userData?.user_studios[0].user_role === "owner") && (
                  <Link
                    className={`${styles.customLink} ${
                      pageId === "planning" && styles.customLinkSelected
                    }`}
                    to={`/planning/${
                      JSON.parse(sessionStorage.getItem("studio"))?.y_id
                    }`}
                  >
                    Планирование
                  </Link>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      {(JSON.parse(sessionStorage.getItem("studio"))?.user_role === "owner" ||
        JSON.parse(sessionStorage.getItem("studio"))?.user_role === "manager" ||
        userData?.user_studios[0].user_role === "owner" ||
        userData?.user_studios[0].user_role === "manager") && (
        <div
          key="settings"
          onMouseEnter={() => handleMouseEnter("settings")}
          onMouseLeave={handleMouseLeave}
          className={`${styles.customMenuItem}`}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px 0",
              backgroundColor: openMenu === "sattings" && "#5DCBC499",
              borderRadius: "2px 2px 0 0",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 12,
              }}
            >
              <img src={IconSettings} alt="Настройки" />
            </div>
          </div>
          {openMenu === "settings" && (
            <div className={styles.subMenuItemContainer}>
              <Link
                className={`${styles.customLink} ${
                  (pageId === "settings" ||
                    pageId === "user" ||
                    pageId === "types" ||
                    pageId === "admin" ||
                    pageId === "settings_planning") &&
                  styles.customLinkSelected
                }`}
                to={`/settings`}
              >
                Настройки
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
