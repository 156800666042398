import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axios";

export const getPlanning = createAsyncThunk(
  "getPlanning",
  async ({ studio_y_id, date }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `studio_forecast/studio/${studio_y_id}/forecast/`,
        {
          params: date,
        }
      );
      return data;
    } catch (err) {
      if (err.code === "ERR_NETWORK") return rejectWithValue(err);
      else return rejectWithValue(err.response);
    }
  }
);

const initialState = {
  planning: null,
  success: {
    success: false,
  },
  errors: {
    error: null,
  },
  loaders: {
    common: false,
  },
};

const planningSlice = createSlice({
  name: "planning",
  initialState,
  reducers: {
    clearError: (state) => {
      state.errors.error = null;
    },
    clearSuccess: (state) => {
      state.success.success = false;
    },
  },

  extraReducers: (builder) => {
    // getPlanning
    builder.addCase(getPlanning.pending, (state) => {
      state.loaders.common = true;
      state.errors.error = null;
    });
    builder.addCase(getPlanning.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.planning = payload;
    });
    builder.addCase(getPlanning.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.error = action?.payload?.data;
    });
  },
});
export const { clearError, clearSuccess } = planningSlice.actions;
export default planningSlice.reducer;
