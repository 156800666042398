import { useEffect, useState, useRef } from "react";
import styles from "./styles.module.css";
import {
  Input,
  Button,
  Table,
  Badge,
  Select,
  Space,
  Spin,
  Row,
  Col,
  Avatar,
  Tooltip,
} from "antd";
import { DatePickerPopover } from "../../components/DatePickerPopover";
import { setOpenInfo } from "../../store/user";
import { InfoModal } from "../../components/InfoModal";
import { DetailNetworkModal } from "../../components/DetailNetworkModal";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import {
  isInclude,
  convertToLocalDate,
  convertDateToISO861,
  selectDropdown,
  updateFilters,
  updateFiltersArr,
} from "../../utils/utils";
import filters from "../../images/filters.svg";
import iconNotSuccessful from "../../images/iconNotSuccessful.svg";
import iconSuccessful from "../../images/iconSuccessful.svg";
import { useDispatch, useSelector } from "react-redux";
import { clearErrorStudio } from "../../store/studios";
import { getOpenInfoSelector } from "../../store/user/selectors";
import {
  getNetworkCorrections,
  clearError,
} from "../../store/networkCorrections";
import {
  getCorrectionsNetworkData,
  getCorrectionsNetworkLoading,
  getError,
  getSuccess,
  getEventsNextPage,
} from "../../store/networkCorrections/selectors";
import { getStudioDropDown } from "../../store/networks";
import {
  getStudioData,
  getStudioDropDownSelector,
} from "../../store/networks/selectors";
import { getStaffDropDownNetwork } from "../../store/staff";
import { getStaffDropDownNetworkSelector } from "../../store/staff/selectors";
import displayErrors from "../../utils/displayErrors";

export const CorrectionsNetwork = () => {
  const dispatch = useDispatch();
  const studioData = useSelector(getStudioData);
  const error = useSelector(getError);
  const correctionsNetworkData = useSelector(getCorrectionsNetworkData);
  const [width, setWidth] = useState(window.innerWidth);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedGuarantee, setSelectedGuarantee] = useState(null);
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [selectedStudio, setSelectedStudio] = useState([]);
  const [includeStaff, setIncludeStaff] = useState(true);
  const [includeStudio, setIncludeStudio] = useState(true);
  const [includeStatus, setIncludeStatus] = useState(true);
  const [includeGuarantee, setIncludeGuarantee] = useState(true);
  const [fileSearch, setFileSearch] = useState("");
  const [filtersArray, setFiltersArray] = useState([]);
  const [openFiltersRow, setOpenFiltersRow] = useState(false);
  const [dateAfterFilter, setDateAfterFilter] = useState(null);
  const [dateBeforeFilter, setDateBeforeFilter] = useState(null);
  const [openDate, setOpenDate] = useState(false);
  const [viewDataFilter, setViewDataFilter] = useState(null);
  const [openCorrectionsNetworkDetails, setOpenCorrectionsNetworkDetails] =
    useState(false);
  const isLoadingCorrectionsNetwork = useSelector(getCorrectionsNetworkLoading);
  const staffList = useSelector(getStaffDropDownNetworkSelector);
  const studioList = useSelector(getStudioDropDownSelector);
  const openInfo = useSelector(getOpenInfoSelector);
  const success = useSelector(getSuccess);
  const [needToLoad, setNeedToLoad] = useState(false);
  const nextPage = useSelector(getEventsNextPage);
  const lastItem = useRef();
  const observer = useRef();

  const columns = [
    createColumn(
      "Студия",
      "studio",
      (v) => {
        return <span>{v?.y_title}</span>;
      },
      100
    ),
    createColumn(
      "Дата",
      "pub_date",
      (v) => {
        let value = convertToLocalDate(v);
        const [date] = value.split(" ");
        return <span style={{ color: "#4B4F55" }}>{date}</span>;
      },
      50
    ),
    createColumn(
      "Mастер",
      "master",
      (_, row) => {
        return (
          <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
            <Avatar
              style={{ width: 25, minWidth: 25, height: 25 }}
              src={row?.master?.y_avatar}
            />
            <Space direction="vertical" style={{ gap: 0 }}>
              <span style={{ textWrap: "nowrap" }}>{row?.master?.y_name}</span>
              <span
                style={{
                  color: "#8B8B8B",
                  fontSize: 12,
                  lineHeight: "14.52px",
                  textWrap: "nowrap",
                }}
              >
                {row?.master?.y_specialization}
              </span>
            </Space>
          </div>
        );
      },
      100
    ),
    createColumn(
      "Тип",
      "corr_type",
      (v) => {
        return (
          <Row>
            <Col style={{ fontSize: "12px" }}>{v}</Col>
          </Row>
        );
      },
      100
    ),
    createColumn(
      "Сумма",
      "cost",
      (v) => {
        if (v) {
          return <p>{v} p.</p>;
        }
      },
      100
    ),
    createColumn(
      "Комментарий",
      "comment",
      (v) => {
        return (
          <Tooltip
            color="#FFFFFF"
            title={<span style={{ color: "#4B4F55" }}>{v}</span>}
          >
            <p className={styles.commentText}>{v}</p>
          </Tooltip>
        );
      },
      150
    ),
    createColumn(
      "Коррекция из оборота",
      "rev_corr",
      (v) => {
        if (v) {
          return <p>{v} p.</p>;
        }
      },
      100
    ),
    createColumn(
      "Создано автоматически",
      "created_by_system",
      (v) => {
        return v ? (
          <img src={iconSuccessful} alt="Успешно" />
        ) : (
          <img src={iconNotSuccessful} alt="Не успешно" />
        );
      },
      100
    ),
  ];

  const status = [
    { name: "Да", bol: true },
    { name: "Нет", bol: false },
  ];

  function createColumn(title, key, render, width, align) {
    return { title, key, dataIndex: key, render, width, align };
  }

  const changeArrayFilters = ({ key, value, text, title }) => {
    const isIncludes = (el, filterName) => {
      return el.key.includes(filterName) !== key.includes(filterName);
    };

    let filtersResult = filtersArray.filter((i) => {
      return isIncludes(i, "master") || isIncludes(i, "is_warranty");
    });

    setFiltersArray(
      value || value === 0
        ? [
            ...filtersResult,
            { key: key, value: value, text: text, title: title },
          ]
        : filtersResult
    );
  };

  const handleChangeDateAfter = (e) => {
    if (e?.$d) {
      setDateAfterFilter(e?.$d.toLocaleDateString("ru-RU"));
      if (dateBeforeFilter) {
        setViewDataFilter(
          e?.$d.toLocaleDateString("ru-RU") + " - " + dateBeforeFilter
        );
      } else {
        setViewDataFilter(e?.$d.toLocaleDateString("ru-RU") + " - ");
      }
    } else {
      if (dateAfterFilter) {
        setViewDataFilter(dateBeforeFilter);
      }
      setDateAfterFilter(null);
    }
  };

  const handleChangeDateBefore = (e) => {
    if (e?.$d) {
      setDateBeforeFilter(e?.$d.toLocaleDateString("ru-RU"));
      if (dateAfterFilter) {
        setViewDataFilter(
          dateAfterFilter + " - " + e?.$d.toLocaleDateString("ru-RU")
        );
      } else {
        setViewDataFilter(" - " + e?.$d.toLocaleDateString("ru-RU"));
      }
    } else {
      if (dateBeforeFilter) {
        setViewDataFilter(dateAfterFilter);
      }
      setDateBeforeFilter(null);
    }
  };

  const fetchData = (pagination = false) => {
    let filters = false;
    let offsetValue = null;
    let limitValue = null;

    if (dateAfterFilter || dateBeforeFilter) {
      filters = {
        start_date: convertDateToISO861(dateAfterFilter),
        end_date: convertDateToISO861(dateBeforeFilter),
      };
    }

    // if (pagination && nextPage) {
    //   const urlParams = new URLSearchParams(nextPage.split("?")[1]);
    //   offsetValue = urlParams.get("offset");
    // }

    if (pagination && nextPage) {
      const urlParams = new URLSearchParams(nextPage.split("?")[1]);
      limitValue = Number(urlParams.get("limit")) + 25;
    }

    dispatch(
      getNetworkCorrections({
        network_y_id:
          JSON.parse(sessionStorage.getItem("studio"))?.y_id ||
          studioData?.y_id,
        filters: filters,
        filtersArray: filtersArray,
        // cursor: pagination ? offsetValue : null,
        limit: pagination ? limitValue : null,
        pagination: pagination,
      })
    );
  };

  const clearFilters = () => {
    setSelectedGuarantee(null);
    setSelectedStaff([]);
    setSelectedStatus(null);
    setSelectedStudio([]);
    setFileSearch("");
    setIncludeStaff(true);
    setIncludeStatus(true);
    setIncludeStudio(true);
    setIncludeGuarantee(true);
    setViewDataFilter(null);
    setDateBeforeFilter(null);
    setDateAfterFilter(null);
    setFiltersArray([]);
  };

  useEffect(() => {
    dispatch(clearErrorStudio());
    dispatch(clearError());
  }, []);

  useEffect(() => {
    if (studioData) {
      dispatch(
        getStudioDropDown(
          JSON.parse(sessionStorage.getItem("studio"))?.y_id || studioData?.y_id
        )
      );
    }
  }, [JSON.parse(sessionStorage.getItem("studio"))?.y_id, studioData?.y_id]);

  useEffect(() => {
    if (studioData) {
      dispatch(
        getStaffDropDownNetwork(
          JSON.parse(sessionStorage.getItem("studio"))?.y_id || studioData?.y_id
        )
      );
    }
  }, [JSON.parse(sessionStorage.getItem("studio"))?.y_id, studioData?.y_id]);

  useEffect(() => {
    updateFiltersArr(
      "master",
      includeStaff,
      selectedStaff,
      setFiltersArray,
      filtersArray
    );
  }, [includeStatus, selectedStaff]);

  useEffect(() => {
    updateFilters(
      "is_warranty",
      includeGuarantee,
      selectedGuarantee,
      setFiltersArray,
      filtersArray
    );
  }, [includeGuarantee, selectedGuarantee]);

  useEffect(() => {
    updateFiltersArr(
      "studio",
      includeStudio,
      selectedStudio,
      setFiltersArray,
      filtersArray
    );
  }, [includeStudio, selectedStudio]);

  useEffect(() => {
    updateFilters(
      "created_by_system",
      includeStatus,
      selectedStatus,
      setFiltersArray,
      filtersArray
    );
  }, [includeStatus, selectedStatus]);

  useEffect(() => {
    (async () => {
      if (needToLoad && nextPage) {
        fetchData(true);
        setNeedToLoad(false);
      }
    })();
  }, [needToLoad]);

  useEffect(() => {
    if (studioData) {
      const timerRefresh = setTimeout(fetchData, 100);
      return () => {
        clearTimeout(timerRefresh);
      };
    }
  }, [dateAfterFilter, dateBeforeFilter, filtersArray, studioData, success]);

  useEffect(() => {
    if (isLoadingCorrectionsNetwork) return;

    if (observer?.current) observer?.current.disconnect();

    let callback = function (
      entries
      //observer
    ) {
      if (
        entries[0].isIntersecting &&
        nextPage &&
        correctionsNetworkData?.data?.results?.length > 0
      ) {
        setNeedToLoad(true);
      }
    };
    observer.current = new IntersectionObserver(callback);
    if (lastItem?.current instanceof Element) {
      observer.current.observe(lastItem.current);
    } else {
      console.warn("lastItem.current is not a valid DOM element");
    }
  }, [isLoadingCorrectionsNetwork]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, [width]);

  return (
    <>
      <>
        <div
          style={{
            display: "flex",
            gap: width > 640 ? 12 : 9,
            position: "relative",
            paddingBottom: width > 640 ? 25 : 16,
          }}
        >
          <DatePickerPopover
            openDate={openDate}
            setOpenDate={setOpenDate}
            viewDataFilter={viewDataFilter}
            dateAfterFilter={dateAfterFilter}
            dateBeforeFilter={dateBeforeFilter}
            // handleSetDateFilters={handleSetDateFilters}
            handleChangeDateBefore={handleChangeDateBefore}
            handleChangeDateAfter={handleChangeDateAfter}
          />

          {width > 640 && (
            <Space direction="vertical" style={{ width: "100%" }}>
              <Input
                style={{ borderRadius: "2px" }}
                prefix={<SearchOutlined style={{ color: "#C1C2C9" }} />}
                value={fileSearch}
                placeholder="Поиск по телефону, имени клиента, мастеру и типу переделки"
                suffix={
                  <CloseOutlined
                    style={{
                      color: fileSearch ? "red" : "black",
                    }}
                    onClick={() => {
                      setFileSearch("");
                      setFiltersArray(
                        filtersArray.filter((v) => v.key !== "q")
                      );
                    }}
                  />
                }
                onChange={(evt) => {
                  setFileSearch(evt.target.value);
                  changeArrayFilters({
                    item: "",
                    key: "q",
                    value: evt.target.value,
                    text: evt.target.value,
                    title: "q",
                  });
                }}
              />
            </Space>
          )}

          <Space.Compact style={{ alignSelf: "flex-end" }} title="Фильтры">
            <Button
              onClick={() => {
                setOpenFiltersRow(!openFiltersRow);
              }}
              style={{
                borderRadius: "2px",
                width: width > 640 ? "151px" : "67px",
                gap: "20px",
              }}
              icon={
                <Badge count={filtersArray.length} size="small" color="blue">
                  <img
                    style={{
                      color: "#6679A9",
                    }}
                    alt="filters"
                    src={filters}
                  />
                </Badge>
              }
            >
              {width > 640 && "Фильтры"}
            </Button>
          </Space.Compact>

          {(dateAfterFilter ||
            dateBeforeFilter ||
            filtersArray?.length > 0) && (
            <Button
              onClick={clearFilters}
              style={{ borderRadius: 2, backgroundColor: "#4D7CD6" }}
              type={"primary"}
            >
              Сбросить
            </Button>
          )}
        </div>

        {openFiltersRow && (
          <>
            {width < 640 && (
              <Space
                direction="vertical"
                style={{ width: "100%", paddingBottom: 25 }}
              >
                <Input
                  style={{ borderRadius: "2px" }}
                  prefix={<SearchOutlined style={{ color: "#C1C2C9" }} />}
                  value={fileSearch}
                  placeholder="Поиск по телефону, имени клиента, мастеру и типу переделки"
                  suffix={
                    <CloseOutlined
                      style={{
                        color: fileSearch ? "red" : "black",
                      }}
                      onClick={() => {
                        setFileSearch("");
                        setFiltersArray(
                          filtersArray.filter((v) => v.key !== "q")
                        );
                      }}
                    />
                  }
                  onChange={(evt) => {
                    setFileSearch(evt.target.value);
                    changeArrayFilters({
                      item: "",
                      key: "q",
                      value: evt.target.value,
                      text: evt.target.value,
                      title: "q",
                    });
                  }}
                />
              </Space>
            )}
            <div
              style={{
                display: width > 543 ? "flex" : "grid",
                rowGap: width > 640 ? 12 : 22,
                columnGap: width > 640 ? 12 : 7,
                gridTemplateColumns: width < 543 ? "repeat(2, 50%)" : "1fr",
                position: "relative",
                paddingBottom: width > 640 ? 20 : 10,
              }}
            >
              <Space direction="vertical" style={{ width: "100%" }}>
                {isInclude(includeStaff)}
                <Select
                  allowClear={true}
                  optionFilterProp="label"
                  mode="multiple"
                  placeholder="Сотрудник"
                  style={{ width: "100%", textAlign: "left" }}
                  options={staffList?.results?.map((option) => {
                    return {
                      value: option.id,
                      label: option.y_name,
                    };
                  })}
                  dropdownRender={(menu) =>
                    selectDropdown(menu, includeStaff, setIncludeStaff)
                  }
                  value={selectedStaff}
                  onChange={setSelectedStaff}
                />
              </Space>

              <Space direction="vertical" style={{ width: "100%" }}>
                {isInclude(includeStudio)}
                <Select
                  allowClear={true}
                  optionFilterProp="label"
                  placeholder="Название студии"
                  mode="multiple"
                  options={studioList?.studios?.map((option) => {
                    return {
                      value: option.id,
                      label: option.y_title,
                    };
                  })}
                  style={{ width: "100%" }}
                  dropdownRender={(menu) =>
                    selectDropdown(menu, includeStudio, setIncludeStudio)
                  }
                  value={selectedStudio}
                  onChange={setSelectedStudio}
                />
              </Space>

              <Space direction="vertical" style={{ width: "100%" }}>
                {isInclude(includeStatus)}
                <Select
                  allowClear={true}
                  maxTagCount="responsive"
                  style={{ width: "100%", textAlign: "left" }}
                  placeholder="Создано автоматически"
                  value={selectedStatus}
                  onChange={setSelectedStatus}
                  dropdownRender={(menu) =>
                    selectDropdown(menu, includeStatus, setIncludeStatus)
                  }
                  options={status.map((v) => {
                    return { value: v.bol, label: v.name };
                  })}
                />
              </Space>
            </div>
          </>
        )}
      </>
      <div
        style={{
          marginBottom: 10,
          fontSize: 12,
          fontWeight: 300,
          marginLeft: 5,
        }}
      >
        Показано {correctionsNetworkData?.data?.results?.length || 0} из{" "}
        {correctionsNetworkData?.data?.count || 0}
      </div>
      <div
        style={{
          maxHeight: !openFiltersRow
            ? "calc(100vh - 200px)"
            : "calc(100vh - 255px)",
          width: "100%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {error && displayErrors(error)}
        <Table
          columns={columns}
          rowKey={(obj) => obj.id}
          size="small"
          pagination={false}
          loading={isLoadingCorrectionsNetwork && <Spin size="large" />}
          className={`${styles.table} ${styles.table_header}`}
          dataSource={correctionsNetworkData?.data?.results || []}
          onRow={(record) => ({
            onClick: () => {
              setOpenCorrectionsNetworkDetails({
                open: true,
                record: record,
              });
            },
          })}
        />
        <div ref={lastItem} style={{ minHeight: 10, maxHeight: 10 }} />
      </div>
      {openCorrectionsNetworkDetails && (
        <DetailNetworkModal
          openDetails={openCorrectionsNetworkDetails}
          setOpenDetails={setOpenCorrectionsNetworkDetails}
        />
      )}
      {openInfo && (
        <InfoModal
          openModal={openInfo}
          setOpenModal={setOpenInfo}
          title={"Информация по коррекциям сети"}
          text={"Тут будет информация"}
        />
      )}
    </>
  );
};
