import { useEffect, useState, useRef } from "react";
import styles from "./styles.module.css";
import {
  Input,
  Button,
  Table,
  Badge,
  Select,
  Space,
  Spin,
  Row,
  Col,
  Tooltip,
  Avatar,
} from "antd";
import { ModalComponent } from "../../components/ModalComponent";
import { setOpenInfo } from "../../store/user";
import { InfoModal } from "../../components/InfoModal";
import {
  CloseOutlined,
  SearchOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  isInclude,
  formatDateString,
  selectDropdown,
  updateFilters,
  updateFiltersArr,
} from "../../utils/utils";
import filters from "../../images/filters.svg";
import iconNotSuccessful from "../../images/iconNotSuccessful.svg";
import iconSuccessful from "../../images/iconSuccessful.svg";
import { useDispatch, useSelector } from "react-redux";
import { clearErrorStudio } from "../../store/studios";
import {
  getStudioData,
  getStudioLoading,
  getErrorStudio,
} from "../../store/studios/selectors";
import { getOpenInfoSelector } from "../../store/user/selectors";
import {
  getPositionDropDown,
  deleteStaff,
  getStaffs,
  clearErrorDelete,
  clearSuccess,
  clearError,
} from "../../store/staff";
import {
  getPositionDataSelector,
  getStaffsDataSelector,
  getSuccessDelete,
  getSuccess,
  getStaffNextPage,
  getStaffLoading,
  getError,
} from "../../store/staff/selectors";
import displayErrors from "../../utils/displayErrors";
import { useNavigate } from "react-router-dom";

export const StaffPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const studioData = useSelector(getStudioData);
  const error = useSelector(getError);
  const errorStudio = useSelector(getErrorStudio);
  const staffsData = useSelector(getStaffsDataSelector);
  const [width, setWidth] = useState(window.innerWidth);
  const [selectedChain, setSelectedChain] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState([]);
  const [selectedFired, setSelectedFired] = useState([]);
  const [includePosition, setIncludePosition] = useState(true);
  const [includeFired, setIncludeFired] = useState(true);
  const [includeChain, setIncludeChain] = useState(true);
  const [fileSearch, setFileSearch] = useState("");
  const [filtersArray, setFiltersArray] = useState([]);
  const [openFiltersRow, setOpenFiltersRow] = useState(false);
  const isLoadingStudio = useSelector(getStudioLoading);
  const isLoadingStaff = useSelector(getStaffLoading);
  const positionList = useSelector(getPositionDataSelector);
  const successDelete = useSelector(getSuccessDelete);
  const openInfo = useSelector(getOpenInfoSelector);
  const [openStaffDelete, setOpenStaffDelete] = useState(false);
  const success = useSelector(getSuccess);
  const [needToLoad, setNeedToLoad] = useState(false);
  const nextPage = useSelector(getStaffNextPage);
  const lastItem = useRef();
  const observer = useRef();
  const [ordering, setOrdering] = useState({
    y_name: null,
  });

  const columns = [
    createColumn(
      "",
      "notification",
      (v) => {
        return v ? (
          <div
            style={{ width: 12, height: 12, backgroundColor: "#76CEC9" }}
          ></div>
        ) : (
          ""
        );
      },
      30
    ),
    createColumn(
      "Сеть",
      "y_chain",
      (v) => {
        return v ? (
          <img src={iconSuccessful} alt="В сети" />
        ) : (
          <img src={iconNotSuccessful} alt="Не в сети" />
        );
      },
      70
    ),
    createColumn(
      "Сотрудник",
      undefined,
      (_, row) => {
        return (
          <div
            style={{
              display: "flex",
              gap: 12,
              alignItems: "center",
            }}
          >
            <Avatar
              style={{
                width: 25,
                minWidth: 25,
                height: 25,
              }}
              src={row?.y_avatar}
            />
            <Space direction="vertical" style={{ gap: 0 }}>
              <span style={{ textWrap: "nowrap" }}>{row?.y_name}</span>
              <span
                style={{
                  color: "#8B8B8B",
                  fontSize: 12,
                  lineHeight: "14.52px",
                  textWrap: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "170px",
                  display: "flex",
                }}
              >
                {row?.y_specialization}
              </span>
            </Space>
          </div>
        );
      },
      100,
      undefined,
      ordering.y_name,
      () => {
        return 0;
      }
    ),
    createColumn(
      "Нанят",
      undefined,
      (_, row) => {
        return (
          <span style={{ color: "#4B4F55", textWrap: "nowrap" }}>
            {formatDateString(row?.hiring_date)}
          </span>
        );
      },
      100
    ),
    createColumn(
      "Уволен",
      undefined,
      (_, row) => {
        return (
          <span style={{ color: "#4B4F55", textWrap: "nowrap" }}>
            {formatDateString(row?.y_dismissal_date)}
          </span>
        );
      },
      100
    ),
    createColumn("Рейтинг", "y_rating", undefined, 100),
    createColumn(
      "Статус",
      "y_fired",
      (v) => {
        return !v ? "Работает" : "Уволен";
      },
      100
    ),
    createColumn("Схема премирования", "repair_record", undefined, 150),
    createColumn(
      "Пользователь",
      "user",
      (v) => {
        return (
          <Space direction="vertical" style={{ gap: 0 }}>
            <span style={{ textWrap: "nowrap" }}>
              {v?.external_data?.y_name}
            </span>
            <span
              style={{
                color: "#8B8B8B",
                fontSize: 12,
                lineHeight: "14.52px",
                textWrap: "nowrap",
              }}
            >
              {v?.external_data?.y_phone && `+${v?.external_data?.y_phone}`}
            </span>
          </Space>
        );
      },
      100
    ),
    createColumn(
      "Работает официально",
      "is_official",
      (v) => {
        return v ? (
          <img src={iconSuccessful} alt="Официально" />
        ) : (
          <img src={iconNotSuccessful} alt="Не официально" />
        );
      },
      100
    ),
    createColumn(
      "",
      "action",
      (_, v) => {
        return (
          <Tooltip
            color="#FFFFFF"
            title={<span style={{ color: "#4B4F55" }}>Удалить сотрудника</span>}
          >
            <DeleteOutlined
              onClick={(evt) => {
                evt.stopPropagation();
                setOpenStaffDelete({ open: true, data: v });
              }}
              className={styles.icon}
            />
          </Tooltip>
        );
      },
      50
    ),
  ];

  const fired = [
    { name: "Уволенные", bol: true },
    { name: "Не уволенные", bol: false },
  ];

  const chain = [
    { name: "Сетевые сотрудники", bol: true },
    { name: "Не сетевые сотрудники", bol: false },
  ];

  const handleSort = (field) => {
    let tempOrdering = {
      y_name: null,
    };

    switch (ordering[field]) {
      case "ascend":
        tempOrdering[field] = "descend";
        break;
      case "descend":
        tempOrdering[field] = null;
        break;
      default:
        tempOrdering[field] = "ascend";
    }

    setOrdering(tempOrdering);
  };

  const getOrderBy = () => {
    let orderBy = "";

    Object.keys(ordering).forEach((key) => {
      if (ordering[key] === "ascend") orderBy = key;
      if (ordering[key] === "descend") orderBy = "-" + key;
    });

    return orderBy;
  };

  function createColumn(title, key, render, width, align, sortOrder, sorter) {
    return {
      title,
      key,
      dataIndex: key,
      render,
      width,
      align,
      sortOrder,
      sorter,
    };
  }

  const changeArrayFilters = ({ key, value, text, title }) => {
    const isIncludes = (el, filterName) => {
      return el.key.includes(filterName) !== key.includes(filterName);
    };

    let filtersResult = filtersArray.filter((i) => {
      return isIncludes(i, "position");
    });

    setFiltersArray(
      value || value === 0
        ? [
            ...filtersResult,
            { key: key, value: value, text: text, title: title },
          ]
        : filtersResult
    );
  };

  const fetchData = (pagination = false) => {
    let filters = false;
    let offsetValue = null;
    let limitValue = null;

    if (pagination && nextPage) {
      const urlParams = new URLSearchParams(nextPage.split("?")[1]);
      limitValue = Number(urlParams.get("limit")) + 25;
    }

    dispatch(
      getStaffs({
        studio_y_id:
          JSON.parse(sessionStorage.getItem("studio"))?.y_id ||
          studioData?.y_id,
        filters: filters,
        filtersArray: filtersArray,
        // cursor: pagination ? offsetValue : null,
        limit: pagination ? limitValue : null,
        pagination: pagination,
        ordering: getOrderBy(),
      })
    );
  };

  const clearFilters = () => {
    setSelectedPosition([]);
    setSelectedChain(null);
    setSelectedFired(null);
    setFileSearch("");
    setIncludePosition(true);
    setIncludeChain(true);
    setIncludeFired(true);
    setFiltersArray([]);
  };

  useEffect(() => {
    if (selectedFired.length === 0) {
      setSelectedFired(selectedFired?.concat(false));
    }
    dispatch(clearErrorStudio());
    dispatch(clearError());
  }, []);

  useEffect(() => {
    if (studioData) {
      dispatch(getPositionDropDown(studioData?.y_id));
    }
  }, [studioData?.y_id]);

  useEffect(() => {
    updateFiltersArr(
      "position",
      includePosition,
      selectedPosition,
      setFiltersArray,
      filtersArray
    );
  }, [includePosition, selectedPosition]);

  useEffect(() => {
    updateFilters(
      "is_fired",
      includeFired,
      selectedFired,
      setFiltersArray,
      filtersArray
    );
  }, [includeFired, selectedFired]);

  useEffect(() => {
    updateFilters(
      "y_is_chain",
      includeChain,
      selectedChain,
      setFiltersArray,
      filtersArray
    );
  }, [includeChain, selectedChain]);

  useEffect(() => {
    (async () => {
      if (needToLoad && nextPage) {
        fetchData(true);
        setNeedToLoad(false);
      }
    })();
  }, [needToLoad]);

  useEffect(() => {
    if (studioData) {
      const timerRefresh = setTimeout(fetchData, 0);
      return () => {
        clearTimeout(timerRefresh);
      };
    }
  }, [filtersArray, studioData, success, successDelete, ordering]);

  useEffect(() => {
    if (isLoadingStaff) return;

    if (observer?.current) observer?.current.disconnect();

    let callback = function (
      entries
      //observer
    ) {
      if (
        entries[0].isIntersecting &&
        nextPage &&
        staffsData?.data?.results?.length > 0
      ) {
        setNeedToLoad(true);
      }
    };
    observer.current = new IntersectionObserver(callback);
    if (lastItem?.current instanceof Element) {
      observer.current.observe(lastItem.current);
    } else {
      console.warn("lastItem.current is not a valid DOM element");
    }
  }, [isLoadingStaff]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, [width]);

  return (
    <>
      <>
        <div
          style={{
            display: "flex",
            gap: width > 640 ? 12 : 9,
            position: "relative",
            paddingBottom: width > 640 ? 25 : 16,
          }}
        >
          {width > 640 && (
            <Space direction="vertical" style={{ width: "100%" }}>
              <Input
                style={{ borderRadius: "2px" }}
                prefix={<SearchOutlined style={{ color: "#C1C2C9" }} />}
                value={fileSearch}
                placeholder="Поиск по имени сотрудника"
                suffix={
                  <CloseOutlined
                    style={{
                      color: fileSearch ? "red" : "black",
                    }}
                    onClick={() => {
                      setFileSearch("");
                      setFiltersArray(
                        filtersArray.filter((v) => v.key !== "q")
                      );
                    }}
                  />
                }
                onChange={(evt) => {
                  setFileSearch(evt.target.value);
                  changeArrayFilters({
                    item: "",
                    key: "q",
                    value: evt.target.value,
                    text: evt.target.value,
                    title: "q",
                  });
                }}
              />
            </Space>
          )}

          <Space.Compact style={{ alignSelf: "flex-end" }} title="Фильтры">
            <Button
              onClick={() => {
                setOpenFiltersRow(!openFiltersRow);
              }}
              style={{
                borderRadius: "2px",
                width: width > 640 ? "151px" : "67px",
                gap: "20px",
              }}
              icon={
                <Badge count={filtersArray.length} size="small" color="blue">
                  <img
                    style={{
                      color: "#6679A9",
                    }}
                    alt="filters"
                    src={filters}
                  />
                </Badge>
              }
            >
              {width > 640 && "Фильтры"}
            </Button>
          </Space.Compact>

          {filtersArray?.length > 0 && (
            <Button
              onClick={clearFilters}
              style={{ borderRadius: 2, backgroundColor: "#4D7CD6" }}
              type={"primary"}
            >
              Сбросить
            </Button>
          )}
        </div>
        {openFiltersRow && (
          <>
            {width < 640 && (
              <Space
                direction="vertical"
                style={{ width: "100%", paddingBottom: 25 }}
              >
                <Input
                  style={{ borderRadius: "2px" }}
                  prefix={<SearchOutlined style={{ color: "#C1C2C9" }} />}
                  value={fileSearch}
                  placeholder="Поиск по имени сотрудника"
                  suffix={
                    <CloseOutlined
                      style={{
                        color: fileSearch ? "red" : "black",
                      }}
                      onClick={() => {
                        setFileSearch("");
                        setFiltersArray(
                          filtersArray.filter((v) => v.key !== "q")
                        );
                      }}
                    />
                  }
                  onChange={(evt) => {
                    setFileSearch(evt.target.value);
                    changeArrayFilters({
                      item: "",
                      key: "q",
                      value: evt.target.value,
                      text: evt.target.value,
                      title: "q",
                    });
                  }}
                />
              </Space>
            )}
            <div
              style={{
                display: width > 543 ? "flex" : "grid",
                rowGap: width > 640 ? 12 : 22,
                columnGap: width > 640 ? 12 : 7,
                gridTemplateColumns: width < 543 ? "repeat(2, 50%)" : "1fr",
                position: "relative",
                paddingBottom: width > 640 ? 20 : 10,
              }}
            >
              <Space direction="vertical" style={{ width: "100%" }}>
                {isInclude(includeFired)}
                <Select
                  allowClear={true}
                  optionFilterProp="label"
                  placeholder="Не уволенные"
                  options={fired.map((v) => {
                    return { value: v.bol, label: v.name };
                  })}
                  style={{ width: "100%" }}
                  dropdownRender={(menu) =>
                    selectDropdown(menu, includeFired, setIncludeFired)
                  }
                  value={selectedFired}
                  onChange={setSelectedFired}
                />
              </Space>

              <Space direction="vertical" style={{ width: "100%" }}>
                {isInclude(includeChain)}
                <Select
                  allowClear={true}
                  maxTagCount="responsive"
                  style={{ width: "100%", textAlign: "left" }}
                  placeholder="Сетевые"
                  value={selectedChain}
                  onChange={setSelectedChain}
                  dropdownRender={(menu) =>
                    selectDropdown(menu, includeChain, setIncludeChain)
                  }
                  options={chain.map((v) => {
                    return { value: v.bol, label: v.name };
                  })}
                />
              </Space>

              <Space direction="vertical" style={{ width: "100%" }}>
                {isInclude(includePosition)}
                <Select
                  allowClear={true}
                  optionFilterProp="label"
                  placeholder="Должность"
                  style={{ width: "100%", textAlign: "left" }}
                  options={positionList?.map((option) => {
                    return {
                      value: option.pk,
                      label: option.y_title,
                    };
                  })}
                  mode="multiple"
                  dropdownRender={(menu) =>
                    selectDropdown(menu, includePosition, setIncludePosition)
                  }
                  value={selectedPosition}
                  onChange={setSelectedPosition}
                />
              </Space>
            </div>
          </>
        )}
      </>
      <div
        style={{
          marginBottom: 10,
          fontSize: 12,
          fontWeight: 300,
          marginLeft: 5,
        }}
      >
        Показано {staffsData?.data?.results?.length || 0} из{" "}
        {staffsData?.data?.count || 0}
      </div>
      <div
        style={{
          maxHeight: !openFiltersRow
            ? "calc(100vh - 200px)"
            : "calc(100vh - 255px)",
          width: "100%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {(error || errorStudio) && displayErrors(error || errorStudio)}
        <Table
          columns={columns}
          rowKey={(obj) => obj.id}
          size="small"
          pagination={false}
          loading={(isLoadingStudio || isLoadingStaff) && <Spin size="large" />}
          className={`${styles.table} ${styles.table_header}`}
          dataSource={staffsData?.data?.results || []}
          locale={{
            triggerDesc: "сортировать по убыванию",
            triggerAsc: "сортировать по возрастанию",
            cancelSort: "сбросить",
          }}
          onHeaderRow={() => {
            return {
              onClick: (e) => {
                switch (e.target.innerText) {
                  case "СОТРУДНИК":
                    handleSort("y_name");
                    break;
                }
              },
            };
          }}
          onRow={(record) => ({
            onClick: (evt) => {
              evt.stopPropagation();
              sessionStorage.setItem("user", JSON.stringify(record));
              navigate(`/staff_edit/${studioData?.y_id}`);
            },
          })}
          rowClassName={(record) => {
            return record.notification ? styles.rowTable : "";
          }}
        />
        <div ref={lastItem} style={{ minHeight: 10, maxHeight: 10 }} />
      </div>
      {openStaffDelete.open && (
        <ModalComponent
          open={openStaffDelete.open}
          title={
            <>
              Вы уверены, что хотите удалить этого{" "}
              <span style={{ color: "#8B8B8B" }}>
                {openStaffDelete?.data?.y_name}
              </span>{" "}
              сотрудника?
            </>
          }
          body={
            <Row gutter={20}>
              <Col span={12}>
                <Button
                  style={{
                    width: "100%",
                    borderRadius: 4,
                    height: 48,
                  }}
                  onClick={() => {
                    setOpenStaffDelete(false);
                    dispatch(clearErrorDelete());
                  }}
                >
                  Не удалять
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  style={{
                    width: "100%",
                    borderRadius: 4,
                    height: 48,
                    background: "var(--Primary-1-normal, #2E496F)",
                  }}
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    dispatch(clearErrorDelete());
                    dispatch(
                      deleteStaff({
                        studio_y_id: studioData?.y_id,
                        id: openStaffDelete.data.id,
                      })
                    ).then(() => {
                      dispatch(clearSuccess());
                      setOpenStaffDelete(false);
                    });
                  }}
                >
                  Удалить
                </Button>
              </Col>
            </Row>
          }
          onCancel={() => {
            dispatch(clearErrorDelete());
            setOpenStaffDelete(false);
          }}
        />
      )}
      {openInfo && (
        <InfoModal
          openModal={openInfo}
          setOpenModal={setOpenInfo}
          title={"Информация по сотрудникам"}
          text={"Тут будет информация"}
        />
      )}
    </>
  );
};
