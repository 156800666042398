import { useState, useEffect } from "react";
import { Button, Col, Drawer, Form, Input, Row, Typography, Rate } from "antd";
import { useDispatch, useSelector } from "react-redux";
import displayErrors from "../../utils/displayErrors";
import {
  postType,
  clearSuccessCreate,
  clearError,
  setOpenType,
} from "../../store/typeComplaints";
import {
  getSuccessCreate,
  getError,
} from "../../store/typeComplaints/selectors";
import { SuccessModalSide } from "../SuccessModalSide";
import { throwFormError } from "../../utils/utils";
import { getStudioData } from "../../store/studios/selectors";

export const CraeteTypeModal = ({ openTypeCreate }) => {
  const [formCreateType] = Form.useForm();
  const dispatch = useDispatch();
  const openSuccessCreate = useSelector(getSuccessCreate);
  const [width, setWidth] = useState(window.innerWidth);
  const [selectedColor, setSelectedColor] = useState("");
  const [hoverColor, setHoverColor] = useState("");
  const studio = useSelector(getStudioData);
  const error = useSelector(getError);

  const COLORS = {
    1: "#fadb14",
    2: "#fadb14",
    3: "#fadb14",
    4: "#fa8c16",
    5: "#fa8c16",
    6: "#fa8c16",
    7: "#fa8c16",
    8: "#f5222d",
    9: "#f5222d",
    10: "#f5222d",
  };

  const handleChangeColor = (number) => {
    const color = COLORS[number];
    setHoverColor(color);
  };

  const handleClickColor = (number) => {
    const color = COLORS[number];
    setSelectedColor(color);
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, [width]);

  return (
    <Drawer
      styles={{
        body: { padding: width > 640 ? "0 64px 20px" : "0 24px 20px" },
      }}
      open={openTypeCreate}
      onCancel={() => {
        dispatch(clearError());
        dispatch(clearSuccessCreate());
        dispatch(setOpenType());
      }}
      onClose={() => {
        dispatch(clearError());
        dispatch(clearSuccessCreate());
        dispatch(setOpenType());
      }}
      footer={null}
      title={
        <Typography.Title style={{ margin: 0 }} level={3}>
          Создать тип жалобы
        </Typography.Title>
      }
      width={500}
      maskClosable={true}
    >
      {openSuccessCreate ? (
        <SuccessModalSide
          close={() => {
            dispatch(clearError());
            dispatch(clearSuccessCreate());
            dispatch(setOpenType());
          }}
          title="Тип жалобы успешно добавлен!"
        />
      ) : (
        // {loader && <Spin size="large" />}
        <>
          <Form
            style={{ marginTop: "20px" }}
            layout="vertical"
            form={formCreateType}
            onFinish={(data) => {
              data.studio = studio?.id;

              dispatch(
                postType({
                  studio_y_id: studio?.y_id,
                  type: data,
                })
              ).then((state) => {
                if (state?.payload?.errors) {
                  throwFormError(formCreateType, state?.payload);
                }
              });
            }}
          >
            {error && displayErrors(error)}

            <Form.Item name="name" label="Тип жалобы">
              <Input
                placeholder="Введите тип жалобы"
                style={{ borderRadius: 2 }}
              />
            </Form.Item>

            <Form.Item name="critical_level" label="Критичность жалобы">
              <Rate
                // defaultValue={2}
                character={({ index = 0 }) => index + 1}
                count={10}
                style={{ color: `${hoverColor}` || `${selectedColor}` }}
                onChange={handleClickColor}
                onHoverChange={handleChangeColor}
                onMouseLeave={() => {
                  setHoverColor(selectedColor);
                }}
              />
            </Form.Item>

            <Row gutter={24}>
              <Col span={12}>
                <Button
                  style={{ width: "100%", borderRadius: 2 }}
                  onClick={() => {
                    dispatch(setOpenType());
                    dispatch(clearSuccessCreate());
                    dispatch(clearError());
                  }}
                >
                  Отмена
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  style={{
                    width: "100%",
                    borderRadius: 2,
                    backgroundColor: "#76CEC9",
                  }}
                  htmlType="submit"
                  type="primary"
                >
                  Сохранить
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </Drawer>
  );
};
