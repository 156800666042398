export const getCalculationData = ({ calculationNetwork }) =>
  calculationNetwork.calculation;
export const getCalculationLoading = ({ calculationNetwork }) =>
  calculationNetwork.loaders.common;
export const getError = ({ calculationNetwork }) =>
  calculationNetwork.errors.error;
export const getOpenCalculationNetworkSelector = ({ calculationNetwork }) =>
  calculationNetwork.openCalculationNetwork;
export const getSuccess = ({ calculationNetwork }) =>
  calculationNetwork.success.success;
export const getSuccessCreate = ({ calculationNetwork }) =>
  calculationNetwork.success.create;
export const getErrorCreate = ({ calculationNetwork }) =>
  calculationNetwork.errors.create;
