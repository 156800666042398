import { useState, useEffect } from "react";
import { Button, Col, Drawer, Form, Input, Row, Typography, Rate } from "antd";
import { useDispatch, useSelector } from "react-redux";
import displayErrors from "../../utils/displayErrors";
import {
  patchType,
  clearSuccessEdit,
  clearError,
} from "../../store/typeComplaints";
import { getSuccessEdit, getError } from "../../store/typeComplaints/selectors";
import { SuccessModalSide } from "../SuccessModalSide";
import { throwFormError } from "../../utils/utils";
import { getStudioData } from "../../store/studios/selectors";

export const EditTypeModal = ({ openTypeEdit, setOpenTypeEdit }) => {
  const [formEditType] = Form.useForm();
  const dispatch = useDispatch();
  const openSuccessEdit = useSelector(getSuccessEdit);
  const [width, setWidth] = useState(window.innerWidth);
  const [selectedColor, setSelectedColor] = useState("");
  const [hoverColor, setHoverColor] = useState("");
  const studio = useSelector(getStudioData);
  const error = useSelector(getError);

  const COLORS = {
    1: "#fadb14",
    2: "#fadb14",
    3: "#fadb14",
    4: "#fa8c16",
    5: "#fa8c16",
    6: "#fa8c16",
    7: "#fa8c16",
    8: "#f5222d",
    9: "#f5222d",
    10: "#f5222d",
  };

  const handleChangeColor = (number) => {
    const color = COLORS[number];
    setHoverColor(color);
  };

  const handleClickColor = (number) => {
    const color = COLORS[number];
    setSelectedColor(color);
  };

  useEffect(() => {
    dispatch(clearError());
    if (openTypeEdit?.record) {
      formEditType.setFields([
        {
          name: "name",
          value: openTypeEdit?.record?.name || "",
        },
        {
          name: "critical_level",
          value: openTypeEdit?.record?.critical_level || "",
        },
      ]);

      setHoverColor(`${COLORS[openTypeEdit?.record?.critical_level]}`);
      setSelectedColor(`${COLORS[openTypeEdit?.record?.critical_level]}`);
    }
  }, [formEditType, openTypeEdit?.record]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, [width]);

  return (
    <>
      <Drawer
        styles={{
          body: { padding: width > 640 ? "0 64px 20px" : "0 24px 20px" },
        }}
        open={openTypeEdit?.open}
        onCancel={() => {
          dispatch(clearError());
          setOpenTypeEdit({ open: false });
          dispatch(clearSuccessEdit());
        }}
        onClose={() => {
          dispatch(clearError());
          setOpenTypeEdit({ open: false });
          dispatch(clearSuccessEdit());
        }}
        footer={null}
        title={
          <Typography.Title style={{ margin: 0 }} level={3}>
            Редактировать тип жалобы
          </Typography.Title>
        }
        width={500}
        maskClosable={true}
      >
        {openSuccessEdit ? (
          <SuccessModalSide
            close={() => {
              dispatch(clearError());
              setOpenTypeEdit({ open: false });
              dispatch(clearSuccessEdit());
            }}
            title="Тип жалобы успешно изменен!"
          />
        ) : (
          // {loader && <Spin size="large" />}
          <>
            <Form
              style={{ marginTop: "20px" }}
              layout="vertical"
              form={formEditType}
              onFinish={(data) => {
                dispatch(
                  patchType({
                    studio_y_id: studio?.y_id,
                    id: openTypeEdit.record.id,
                    type: data,
                  })
                ).then((state) => {
                  if (state?.payload?.errors) {
                    throwFormError(formEditType, state?.payload);
                  }
                });
              }}
            >
              {error && displayErrors(error)}

              <Form.Item name="name" label="Тип жалобы">
                <Input
                  placeholder="Введите тип жалобы"
                  style={{ borderRadius: 2 }}
                />
              </Form.Item>

              <Form.Item name="critical_level" label="Критичность жалобы">
                <Rate
                  // defaultValue={2}
                  character={({ index = 0 }) => index + 1}
                  count={10}
                  style={{ color: `${hoverColor}` || `${selectedColor}` }}
                  onChange={handleClickColor}
                  onHoverChange={handleChangeColor}
                  onMouseLeave={() => {
                    setHoverColor(selectedColor);
                  }}
                />
              </Form.Item>

              <Row gutter={24}>
                <Col span={12}>
                  <Button
                    style={{ width: "100%", borderRadius: 2 }}
                    onClick={() => {
                      setOpenTypeEdit({ open: false });
                      dispatch(clearError());
                    }}
                  >
                    Отмена
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    style={{
                      width: "100%",
                      borderRadius: 2,
                      backgroundColor: "#76CEC9",
                    }}
                    htmlType="submit"
                    type="primary"
                  >
                    Сохранить
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        )}
      </Drawer>
    </>
  );
};
